const TOGGLE_MENU = "TOGGLE_MENU";
const TOGGLE_DARKMODE = "TOGGLE_DARKMODE";
const AUTH_SET = "AUTH_SET";
const AUTH_INVALIDATE = "AUTH_INVALIDATE";
const ALERT_SHOW = "ALERT_SHOW";
const ALERT_HIDE = "ALERT_HIDE";

export {
  TOGGLE_MENU,
  TOGGLE_DARKMODE,
  AUTH_SET,
  AUTH_INVALIDATE,
  ALERT_SHOW,
  ALERT_HIDE,
};
